<template>
  <v-dialog
    v-model="isShowModal"
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('evoucher.historyTransaction')"
        tile
        color="green"
        full-width
      >
        <v-progress-linear
          v-show="progressBar"
          :indeterminate="progressBar"
          color="success"
          height="5"
          value="30"
        />
        <v-data-table
          :no-data-text="$t('common.noDataAvailable')"
          :headers="voucherHistoryHeaders"
          :items="voucherHistoryList"
          :loading="true"
          hide-actions
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="text--darken-3 font-medium"
              v-text="$t(header.text)"
            />
          </template>
          <template
            slot="items"
            slot-scope="{ item }">
            <td
              v-for="(header, index) in voucherHistoryHeaders"
              :key="header.value + index"
            >
              <div>{{ item[header.value] }}</div>
            </td>
          </template>
        </v-data-table>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          class="mt-2"
          @onPageChange="onPageChange"
        />
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    isShowModal: false,
    voucherHistoryHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherTransferRequest.receivingAgent', value: 'toEntity' },
      { text: 'evoucher.quantity', value: 'quantity' }
    ],
    voucherHistoryList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    progressBar: false,
    workpieceId: null,
    issuanceId: null
  }),
  computed: {
    ...mapGetters([
      'WORKPIECE_DETAIL_HISTORIES_DATA'
    ])
  },
  watch: {
    WORKPIECE_DETAIL_HISTORIES_DATA () {
      let res = this.WORKPIECE_DETAIL_HISTORIES_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let data = res.results
      this.voucherHistoryList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let historyObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          toEntity: data[i].to_entity.name,
          quantity: data[i].quantity
        }
        this.voucherHistoryList.push(historyObj)
      }
    }
  },
  methods: {
    // Page change
    onPageChange: function (page) {
      this.paginate.currentPage = page
    },
    /**
     * GET WORKPIECE detail histories
     */
    getWorkpieceDetailHistories: function () {
      let filter = {
        params: {
          workpieceId: this.workpieceId,
          issuanceId: this.issuanceId,
          page: this.paginate.currentPage
        }
      }
      this.progressBar = true
      this.GET_WORKPIECE_DETAIL_HISTORIES(filter).then(
        function () {
          this.progressBar = false
        }.bind(this)
      ).catch(
        function () {
          this.progressBar = false
        }.bind(this)
      )
    },
    /**
     * Show modal
     */
    onShowModal: function (issuanceId, workpieceId, remain) {
      this.workpieceId = workpieceId
      this.issuanceId = issuanceId
      this.quantityRemain = remain
      this.getWorkpieceDetailHistories()
      this.isShowModal = true
    },
    ...mapActions([
      'GET_WORKPIECE_DETAIL_HISTORIES'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/table.v-table tbody td {
  padding: 0 !important;
}
/deep/table.v-table thead th {
  padding-left: 0 !important;
}
</style>
